import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { noop } from "lodash";
import { getUnitOptions } from "../states/thunks/options.thunk";
import { setWorkingUnit, handleLogout } from "../states/thunks/auth.thunk";
import {
  getNotifications,
  updateNotificationStatus,
} from "../states/thunks/common.thunk";
import { journalAcceptReconciliation } from "../states/thunks/finance.thunk";
import Header from "../components/Header/Header.component";
import Sidebar from "../components/Sidebar/Sidebar.component";
import Home from "../pages/Home.page";
import AlertContextProvider from "../contexts/AlertContext";

import Spp from "../pages/Finance/Spp/Spp.page";
import Pembayaran from "../pages/Finance/Spp/Pembayaran/Pembayaran.page";
import RincianPembayaran from "../pages/Finance/Spp/RincianPembayaran/RincianPembayaran.page";
import Riwayat from "../pages/Finance/Spp/Riwayat/Riwayat.page";

import ManagePassword from "../pages/Settings/ManagePassword.page";

import { remove, storageKeys } from "../utils/storage.util";

import { SIDEBAR__DASHBOARD_MENU } from "../constants/menu.constant";

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSidebar: window.innerWidth > 900,
    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.onPressHome = this.onPressHome.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);
    this.onClickProfile = this.onClickProfile.bind(this);
    this.onClickMenu = this.onClickMenu.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this._onClickBreadcrumb = this._onClickBreadcrumb.bind(this);
  }

  onPressHome() {
    const { history } = this.props;
    history.replace("/dashboard");
  }

  onClickLogout() {
    const { history } = this.props;
    history.replace("/login");
    remove(storageKeys.USER_TOKEN);
  }

  onClickProfile() {
    const { history } = this.props;
    history.push("/dashboard/profile");
  }

  onClickMenu(path, attributes) {
    const { history } = this.props;
    history.push(path, attributes);
  }

  onLogout() {
    const { onLogout, history } = this.props;
    onLogout(history.push);
  }

  _onClickBreadcrumb(index) {
    const { location, history } = this.props;
    const listPath = location.pathname.split("/");
    const newPath = listPath.slice(0, index + 2);
    history.push(newPath.join("/"));
  }

  toggleSidebar() {
    this.setState((prevState) => ({
      showSidebar: !prevState.showSidebar,
    }));
  }

  render() {
    const { showSidebar } = this.state;
    const {
      match,
      location,
      user,
      units,
      onSearchUnit,
      handleSetWorkingUnit,
      handleGetNotifications,
      handleUpdateNotificationStatus,
      notifications,
      history,
      handleAcceptReconciliation,
    } = this.props;

    return (
      <div className="dashboard">
        <Header
          onClickMenu={this.toggleSidebar}
          pathname={location.pathname}
          onClickBreadcrumb={this._onClickBreadcrumb}
          onSearchUnit={onSearchUnit}
          setWorkingUnit={handleSetWorkingUnit}
          user={user}
          units={units}
          history={history}
          notifications={notifications}
          handleGetNotifications={handleGetNotifications}
          handleAcceptReconciliation={handleAcceptReconciliation}
          updateNotificationStatus={handleUpdateNotificationStatus}
        />
        <div className="dashboard__wrapper">
          <Sidebar
            show={showSidebar}
            sidebarMenu={SIDEBAR__DASHBOARD_MENU}
            onClickMenu={this.onClickMenu}
            onLogout={this.onLogout}
            user={user}
          />
          <div className="dashboard__main-panel">
            <div className="dashboard__content-wrapper">
              <AlertContextProvider>
                <Route exact path={match.path} component={Home} />

                <Route
                  exact
                  path={`${match.path}/tagihan-siswa`}
                  component={Spp}
                />
                <Route
                  exact
                  path={`${match.path}/tagihan-siswa/pembayaran`}
                  component={Pembayaran}
                />
                <Route
                  exact
                  path={`${match.path}/tagihan-siswa/rincian-pembayaran`}
                  component={RincianPembayaran}
                />
                <Route
                  exact
                  path={`${match.path}/tagihan-siswa/riwayat`}
                  component={Riwayat}
                />

                <Route
                  exact
                  path={`${match.path}/pengaturan/ganti-kata-sandi`}
                  component={ManagePassword}
                />
              </AlertContextProvider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  onLogout: PropTypes.func,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  units: PropTypes.object,
  onSearchUnit: PropTypes.func,
  handleSetWorkingUnit: PropTypes.func,
  handleGetNotifications: PropTypes.func,
  handleUpdateNotificationStatus: PropTypes.func,
};
Dashboard.defaultProps = {
  onLogout: noop,
  units: null,
  onSearchUnit: noop,
  handleSetWorkingUnit: noop,
  handleGetNotifications: noop,
  handleUpdateNotificationStatus: noop,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    units: state.options.unit,
    notifications: state.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLogout: (push) => dispatch(handleLogout(push)),
    onSearchUnit: (payload) => dispatch(getUnitOptions(payload)),
    handleSetWorkingUnit: (payload) => dispatch(setWorkingUnit(payload)),
    handleGetNotifications: (payload) => dispatch(getNotifications(payload)),
    handleUpdateNotificationStatus: (payload) =>
      dispatch(updateNotificationStatus(payload)),
    handleAcceptReconciliation: (payload) =>
      dispatch(journalAcceptReconciliation(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);