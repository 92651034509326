import swal from 'sweetalert';
import { noop } from 'lodash';
import i18n from '../i18n';
import language from '../languages';


export function successMessage(payload) {
  const {
    message = language.translate.ALERT__OK_TO_CONTINUE,
  } = payload;
  return message;
}

export function errorsMessage(payload) {
  const {
    errors,
    value = null,
  } = payload;
  if (errors && Object.values(errors).length > 0) {
    let concatMessage = '';
    Object.values(errors).forEach((item) => {
      concatMessage = `${item}\r\n`;
    });
    return concatMessage;
  }
  return i18n.t(`apiErrors:${errors}`, { value });
}

export function successAlert(payload) {
  const {
    title = language.translate.ALERT__COMMON_TITLE,
    message = language.translate.ALERT__OK_TO_CONTINUE,
    callback = noop,
  } = payload;
  swal({
    title,
    text: message,
    icon: 'success',
    type: 'success',
  }).then((ok) => {
    if (ok) {
      callback();
      return true;
    }
    return false;
  });
}

export function errorAlert(payload) {
  const {
    title = 'alerts.common.title',
    errorMessage = 'alerts.common.message',
    form = '',
    value = null,
  } = payload;
  swal({
    title: i18n.t(title, { form: i18n.t(form) }),
    text: errorMessage,
    icon: 'warning',
    dangerMode: true,
  });
}

export function deleteConfirmation() {
  return swal({
    title: i18n.t('alerts.confirm.title'),
    text: i18n.t('alerts.confirm.delete'),
    icon: 'warning',
    buttons: [i18n.t('buttons.cancel'), i18n.t('buttons.ok')],
    dangerMode: true,
  });
}

export const actionConfirmation = (func = noop, payload, title = null) => swal({
  title: title === null ? i18n.t('alerts.confirm.title') : title,
  text: language.translate.ALERT__OK_TO_CONTINUE,
  icon: 'warning',
  buttons: [i18n.t('buttons.cancel'), i18n.t('buttons.ok')],
  dangerMode: true,
}).then((willDelete) => {
  if (willDelete) {
    func(payload);
    return true;
  }
  return false;
});

export function successAlertBack(payload) {
  const {
    title = language.translate.ALERT__COMMON_TITLE,
    message = language.translate.ALERT__OK_TO_CONTINUE,
    goBack 
  } = payload;
  swal({
    title,
    text: message,
    icon: 'success',
  }).then(() => {
    goBack();
  });;
}